.footer {
  background-color: var(--ColorPrincipalBack);
  color: var(--FuentePrincipal);
  padding: 10px 0; /* Reducir el padding para hacer el footer más compacto */
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  font-family: var(--FuenteSpaceGrotesk);
}

.footer-section {
  text-align: center;
}

.footer-section h4 {
  color: var(--FuenteSecundaria);
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 1.2rem;
  font-family: var(--FuenteRubik);
}

.footer-section p,
.footer-section a {
  color: var(--FuentePrincipal);
  font-size: 1rem;
  text-decoration: none;
  font-family: var(--FuenteSpaceGrotesk);
}

.footer-section a:hover {
  color: var(--ColorResalte);
}

.social-links {
  display: flex;
  gap: 10px;
  justify-content: center; /* Alineación a la izquierda */
}

.social-link {
  color: var(--FuentePrincipal);
  font-size: 1.5rem;
  text-decoration: none;
}

.social-link:hover {
  color: var(--ColorResalte);
}

.footer-separator {
  width: 100%; /* Ajuste al 100% del contenedor para evitar scroll horizontal */
  border: 0.5px solid var(--ColorResalte);
  margin: 10px 0; /* Reducir margen para hacerlo más compacto */
  box-sizing: border-box; /* Asegura que el borde no aumente el ancho */
}

.footer-bottom {
  color: var(--FuentePrincipal);
  font-size: 0.8rem; /* Reducir tamaño de fuente */
  font-family: var(--FuenteRubik);
}

/* Diseño horizontal en pantallas grandes, ocupando 100% del ancho y alineado hacia arriba */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* Alineación hacia arriba */
  }

  .footer-section {
    flex: 1; /* Cada sección ocupa un tercio del ancho */
    text-align: left; /* Alinear el contenido al lado izquierdo */
  }
  .social-links {
    justify-content: flex-start;
  }
}